import React from "react";

import { Grid, Icon } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { makeStyles } from '@mui/styles';

import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
    footerSection: {
        "& h4:after": {
            content: '" "',
            position: "absolute",
            bottom: -8,
            left: 0,
            height: 2,
            width: 64,
            background: "#00ee64",
        },
    },
}));

const Footer1 = () => {
    const classes = useStyles();

    return (
        <div className={clsx("bg-light-dark", classes.footerSection)} id="footer1">
            <div className="container">
                <Grid container>
                    <Grid item lg={12} md={12} style={{ width: '100%' }}>
                        <div className="p-10 h-full">
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} style={{ width: '100%' }}>
                        <div className="p-8 h-full elevation-z3">
                            <h4 className="text-20 mb-6 relative">Quem somos</h4>
                            <div className="px-4 flex items-center mx--4">
                                <Icon className="text-green-light">corporate_fare</Icon>
                                <div className="pl-4">
                                    <p className="m-0 p-0 text-inherit" style={{ textAlign: 'justify' }}>
                                    Empresa fundada em 1975, com foco e pioneirismo em documentos eletrônicos com validade jurídica.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} style={{ width: '100%' }}>
                        <div className="p-8 h-full elevation-z3">
                            <h4 className="text-20 mb-6 relative">Sede</h4>
                            <div className="px-4 flex items-center mx--4">
                                <Icon className="text-green-light">location_on</Icon>
                                <div className="pl-4">
                                    <p className="m-0 p-0 text-inherit" style={{ textAlign: 'justify' }}>
                                        Rua Machado Bittencourt, 361<br />14º Andar, Conjunto 1401/1403<br />Vila Clementino - São Paulo / SP<br />CEP 04044-001
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={4} md={4} style={{ width: '100%' }}>
                        <div className="p-8 h-full elevation-z3">
                            <h4 className="text-20 mb-6 relative">Contato</h4>
                            <div className="px-4 flex items-center mx--4">
                                <a href="https://api.whatsapp.com/send?l=pt_br&phone=5511999827085" target="_blank" rel="noreferrer">
                                    <WhatsAppIcon className={'text-green-light'} size={18}></WhatsAppIcon>
                                </a>
                                <div className="pl-4">
                                    <p className="m-0 p-0 text-inherit" style={{ textAlign: 'justify' }}>
                                        Para contato, use o Whatsapp
                                    </p>
                                    <p className="m-0 p-0 text-inherit"><a href="https://api.whatsapp.com/send?l=pt_br&phone=5511999827085" target="_blank" rel="noreferrer">(11) 9 9982-7085</a></p>
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} style={{ width: '100%' }}>
                        <div className="p-10 h-full">
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Footer1;
