import React from "react";
import { Grid, Card } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";

const useStyles = makeStyles(({ palette, ...theme }) => ({
  card: {
    transition: "all 400ms ease-in-out",
    marginTop: "40px",

    "& .card-icon": {
      position: "absolute",
      fontSize: 200,
      height: 200,
      width: 200,
      left: -80,
      bottom: -80,
      zIndex: 1,
      opacity: 0.24,
      transition: "all 400ms ease-in-out",
    },

    "&:hover": {
      transform: "translateY(-8px)",

      "& .card-icon": {
        transform: "translateY(-8px)",
        color: palette.secondary.main,
        opacity: 0.6,
      },
    },
  },
}));

const Service4 = () => {
  const classes = useStyles();

  return (
    <div className="section section-service4 bg-light-gray" id="service4">
      <div className="container">
        <div className="section__header">
            <Grid container spacing={5}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <h3>Onde encontrar seu código?</h3>

                <Card
                  className={clsx(
                    "relative overflow-hidden card",
                    classes.card
                  )}
                >

                <Grid container spacing={5}>
                  <Grid className="text-center" item lg={12} md={6} sm={12} xs={12}>
                    <img className="img-fluid max-w-400" src="./assets/images/documento.svg" alt="" />
                  </Grid>
                  <Grid className="text-center" item lg={12} md={6} sm={12} xs={12}>
                    <h3 className="mt-4">Encontre sua chave para pesquisa aqui.</h3>
                    <p className="m-0 mb-10">Digite os últimos 21 cacteres, inclusive as letras, localizado logo acima do rodapé de seu documento. Ex.: 2024020500099999999CA</p>
                  </Grid>
                </Grid>

                </Card>
                
              </Grid>

            </Grid>
        </div>
      </div>
    </div>
  );
};

export default Service4;
