import React, { useEffect } from "react";
import { scrollTo } from "../utils";
import Service4 from "./sections/Services4";
import Intro3 from "./sections/Intro3";
import TopBar3 from "./sections/TopBar3";
import Footer1 from "./sections/Footer1";

const Landing3 = () => {
  useEffect(() => {
    scrollTo("root");
  }, []);

  return (
    <div className="landing">
      <TopBar3 />
      <Intro3 />
      <Service4 />
      <Footer1 />
    </div>
  );
};

export default Landing3;
