import React, { useState, useEffect } from "react";
import { debounce, classList } from "../../utils";
import { Icon, IconButton, useMediaQuery, Fab } from "@mui/material";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

import ScrollTo from "../common/ScrollTo";

import { useTheme } from '@mui/material/styles';

const TopBar3 = (props) => {
    const [isTop, setIsTop] = useState(true);
    const [isClosed, setIsClosed] = useState(true);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    let scrollableElement = document.querySelector(".scrollable-content");
    if (!scrollableElement) scrollableElement = window;

    let handleScrollRef = null;
    let toggleIcon = isClosed ? "menu" : "close";

    const handleScroll = () => {
        return debounce(({ target: { scrollTop } }) => {
            let isCurrentTop = scrollTop < 100 || scrollableElement.scrollY < 100;
            setIsTop(isCurrentTop);
        }, 20);
    };

    handleScrollRef = handleScroll();

    const close = () => {
        setIsClosed(false);
    };

    useEffect(() => {
        if (scrollableElement) {
            scrollableElement.addEventListener("scroll", handleScrollRef);
        }

        return () => {
            if (scrollableElement) {
                scrollableElement.removeEventListener("scroll", handleScrollRef);
            }
        };
    }, [scrollableElement, handleScrollRef]);

    const focusValidarDocumento = async () => {
        const campoValidarDocumento = document.querySelector('#campoValidarDocumento');
        campoValidarDocumento.focus();
    };

    return (
        <section
            className={classList({
                header: true,
                "bg-white": isTop && !isMobile,
                "header-fixed": !isTop,
                closed: isClosed,
            })}
        >
            <div className="container header-container">
                <div className="brand">
                    <img src="./assets/images/logo_cartoon.png" alt="" />
                </div>
                <ul className="navigation">
                    <li>
                        <ScrollTo to="intro3" focus={focusValidarDocumento} onScroll={close}>
                            Validar documento
                        </ScrollTo>
                    </li>

                    <li>
                        <ScrollTo to="service4" onScroll={close}>
                            Dúvidas
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="footer1" onScroll={close}>
                            Quem somos
                        </ScrollTo>
                    </li>
                    <li>
                        <ScrollTo to="footer1" onScroll={close}>
                            Contato
                        </ScrollTo>
                    </li>
                    <li>&nbsp;</li>
                </ul>
                <div className="m-auto" />
                <a href="https://api.whatsapp.com/send?l=pt_br&phone=5511999827085" target="_blank" rel="noreferrer">
                    <Fab
                        variant="extended"
                        size="medium"
                        // color="bg-secondary"
                        aria-label="Buy"
                        className="bg-secondary btn-on-hover-green-light text-white"
                        style={{ lineHeight: 'normal' }}
                    >
                        <WhatsAppIcon className={'text-white mr-2'} size={18}></WhatsAppIcon>
                        Whatsapp
                    </Fab>
                </a>
                <IconButton
                    className="header__toggle"
                    style={{ fontSize: '0rem' }}
                    onClick={() => {
                        setIsClosed(!isClosed);
                    }}
                >
                    <Icon>{toggleIcon}</Icon>
                </IconButton>
            </div>
        </section>
    );
};

export default TopBar3;
