import React, { useState, useEffect, useCallback } from "react";
import { Grid, Icon, Button, TextField, Box } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from "clsx";
import axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles(({ palette, ...theme }) => ({
    introWrapper: {
        padding: "5rem 0px !important",
        paddingBottom: "0rem !important",
        overflow: "visible !important",
        marginTop: "4rem",

        [theme.breakpoints.down("sm")]: {
            padding: "1rem 0 !important",
            textAlign: "center",
            marginTop: "0rem",
            "& .list": {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            },
        },
    },
    title: {
        textShadow: "0 4px 4px rgba(0, 0, 0, 0.22)",
    },
}));

const Intro3 = () => {
    const { numeroDocumento } = useParams();

    const classes = useStyles();
    const [documentoChave, setDocumentoChave] = useState('');
    const [linkDocumento, setLinkDocumento] = useState('');
    const [documentoInvalido, setDocumentoInvalido] = useState(false);
    const [carregando, setCarregando] = useState(false);

    const validarDocumento = useCallback(async (documentoChave) => {
        try {
            if(documentoChave === ''){
                return
            }
            
            // Verifica se é uma chave que inicia com o ano ou se é um uuid válido
            if(!/^(19|20)\d{2}/.test(documentoChave) && !/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/.test(documentoChave)){
                throw new Error('Documento inválido');
            }

            setCarregando(true);

            await axios.get(`${process.env.REACT_APP_ENDPOINT_API_VALIDAR_DOCUMENTO}/${documentoChave.replace(/[^a-zA-Z0-9-]/g, "")}`);

            setLinkDocumento(`${process.env.REACT_APP_ENDPOINT_API_VALIDAR_DOCUMENTO}/${documentoChave.replace(/[^a-zA-Z0-9-]/g, "")}`);
            setDocumentoInvalido(false);
            setCarregando(false);
        } catch (error) {
            setLinkDocumento('');
            setDocumentoInvalido(true);
            setCarregando(false);
            console.error(error);
        }
    }, []);

    useEffect(() => {
        if(numeroDocumento){
            setDocumentoChave(numeroDocumento);
            validarDocumento(numeroDocumento);
        }
    }, [numeroDocumento, validarDocumento]);

    return (
        <section className="section" id="intro3">
            <div className={classes.introWrapper}>
                <div className="container">
                    <Grid className="justify-center" container spacing={3} justify="center" alignItems="start">
                        <Grid item md={6}>
                            <h1 className={clsx("text-48 mb-6 text-primary text-green", classes.title)}>
                                Validar documento
                            </h1>
                            <div className="list mb-10">
                                <div className="flex items-center mb-4 text-muted">
                                    <Icon className="mr-2" color="secondary">
                                        check
                                    </Icon>
                                    Fácil
                                </div>
                                <div className="flex items-center mb-4 text-muted">
                                    <Icon className="mr-2" color="secondary">
                                        check
                                    </Icon>
                                    Prático
                                </div>
                                <div className="flex items-center mb-4 text-muted">
                                    <Icon className="mr-2" color="secondary">
                                        check
                                    </Icon>
                                    Rápido
                                </div>
                            </div>

                            <Box className="max-w-450 mb-5">
                                <TextField
                                    id="campoValidarDocumento"
                                    variant="outlined"
                                    size="small"
                                    value={documentoChave}
                                    placeholder="Chave do Documento"
                                    onChange={(e) => setDocumentoChave(e.target.value)}
                                    fullWidth
                                    InputProps={{
                                        style: {
                                            borderRadius: 300,
                                            padding: "2px 3px",
                                            paddingLeft: "0.75rem",
                                            background: "rgba(255,255,255,0.87)",
                                        },
                                        endAdornment: (
                                            <Button 
                                                onClick={() => validarDocumento(documentoChave)}
                                                className="bg-secondary btn-on-hover-green-light rounded text-white px-7 py-11px btn-validar"
                                            >
                                                {!carregando && (
                                                    <>
                                                        <Icon fontSize="small">check</Icon>
                                                        <span className="ml-2" style={{ lineHeight: 'normal', margin: '0px 9px' }}>VALIDAR&nbsp;</span>
                                                    </>
                                                )}
                                                {carregando && (
                                                    <>
                                                        <span className="ml-2" style={{ lineHeight: 'normal', marginBottom: '1px', marginTop: '2px' }}>VALIDANDO...</span>
                                                    </>
                                                )}
                                            </Button>
                                        ),
                                    }}
                                />
                                {linkDocumento && (
                                    <Box
                                        className="flex flex-wrap justify-center items-center max-w-360 mx-auto mt-5 max-w-450"
                                        gap={2}
                                    >
                                        <Box fontSize={20} className="flex items-center">
                                            <Icon className="mr-2" color="success">
                                                check_circle
                                            </Icon>
                                            Documento validado com sucesso!
                                        </Box>
                                        <Box>
                                            <a href={linkDocumento}>
                                                <Button 
                                                    className="bg-primary btn-on-hover-primary-dark rounded text-white px-7 py-11px"
                                                >
                                                    <Icon fontSize="small">download</Icon>
                                                    <span className="ml-2" style={{ lineHeight: 'normal' }}>Baixar versão digital</span>
                                                </Button>
                                            </a>
                                        </Box>
                                    </Box>
                                )}
                                {documentoInvalido && (
                                    <Box
                                        className="flex flex-wrap justify-center items-center max-w-360 mx-auto mt-5 max-w-450"
                                        gap={2}
                                    >
                                        <Box fontSize={20} className="flex items-center">
                                            <Icon className="mr-2" color="error">
                                                error
                                            </Icon>
                                            Documento não encontrado!
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <div className="flex justify-center items-center max-w-360 mx-auto">
                                <img
                                    className="w-full"
                                    src="./assets/images/illustrations/2.svg"
                                    alt=""
                                />
                            </div>
                            <div id="service4"></div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </section>
    );
};

export default Intro3;
